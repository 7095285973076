import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"

export default function Error404({ data }) {
  const seo = {
    title: "404, strona której szukasz nie istnieje",
    description: "Adres: ul. Dębowa 3, 72-003 Dobra Szczecińska k/Szczecina | tel. 91 311 22 28, tel. +48 502 135 712 | E-mail: kontakt@mpastucha.pl",
    url: "https://mpastucha.pl/"
  }

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
      />
      <a href="tel:913112228" className="phonelink"><img src="https://mpastucha.pl/wp-content/themes/Pastucha/img/ico-call.svg" alt="Ikona telefon" /></a>
      <section className="fullheight hero-bg-dr cover">
        <div className="hero-cnt">
          <div className="txt-title txt-white">
            <h1>Nie odnaleziono strony.</h1>
          </div>
          <div className="txt-white txt-light smplus-only"> <a href="https://mpastucha.pl" className="btn-link-arrow btn-link-arrow-r ">Kliknij tutaj aby wrócić na stronę główną. <span className="ico-arrow" /></a></div>
        </div>
      </section>

    </Layout>
  )
}
